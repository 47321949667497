<template>
  <div class="page-wrapper rep-referral-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Rep Referral</h1>
      </div>
      <div>
       <send-reffer @onSend="getReferralList"></send-reffer>
      </div>
      <div class="mt-4 rep-referral-table-card">
      <rep-referral-list :rows.sync="rows" ></rep-referral-list>
      <pagination :rows.sync="rows" />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/resource/Api";
import SendReffer from "./partials/SendReffer.vue";
import RepReferralList from "./partials/RepReferralList.vue";
 export default  {
   components: {
     SendReffer,
     RepReferralList
   },
   name: 'RepReferralView',
   data () {
     return {
       breadcrumbItems: [{ title: "Rep Referral", active: true }],
       rows: []
     }
   },
   mounted () {
     let app = this
     app.getAll();
   },
   methods: {
     getReferralList () {
       let app = this
       app.getAll();
     },
     getAll() {
       let url;
       let loader = this.$loading.show();
         url = `v2/referal/get`;
       Api.get(url)
           .then((res) => {
             this.rows = res.data;
           })
           .catch(() => {
             this.rows = [];
             console.log("error");
           })
           .finally(() => {
             loader.hide();
           });
     },
  }
 }
</script>


<style src="@/assets/css/rep-referral.css">

</style>
