var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "referral-info"
  }, [_vm._v(" " + _vm._s(_vm.rows.info) + " ")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [!_vm.rows.records ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "7",
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('strong', [_vm._v(_vm._s(row.name))])]), _c('td', [_c('div', [_c('strong', [_vm._v("Referral Date:")]), _vm._v(" " + _vm._s(row.created_date))]), _c('div', [_c('strong', [_vm._v("Registered Date:")]), _vm._v(" " + _vm._s(row.enrolled_date))]), _c('div', [_c('strong', [_vm._v("Approved Date:")]), _vm._v(" " + _vm._s(row.approved_date))])]), _c('td', [_vm._v(_vm._s(row.status_text))]), _c('td', [!row.is_registered ? _c('button', {
      staticClass: "referral-btn",
      on: {
        "click": function click($event) {
          return _vm.resendReferralLink(row.id);
        }
      }
    }, [_vm._v("Resend")]) : _vm._e()])]);
  })], 2)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Date")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Actions")])])]);
}]

export { render, staticRenderFns }