<template>
  <div>
  <div class="referral-info">
    {{rows.info}}
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Date</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!rows.records">
        <td colspan="7" align="center">No records found.</td>
      </tr>
      <tr
          v-for="(row, index) in rows.records"
          :key="index"
      >
        <td><strong>{{row.name}}</strong></td>
        <td>
          <div><strong>Referral Date:</strong> {{row.created_date}}</div>
          <div><strong>Registered Date:</strong> {{row.enrolled_date}}</div>
          <div><strong>Approved Date:</strong> {{row.approved_date}}</div>
        </td>
        <td>{{row.status_text}}</td>
        <td>
          <button class="referral-btn" @click="resendReferralLink(row.id)"
                  v-if="!row.is_registered">Resend</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "RepReferralList",
  props: {
    rows: {
      required: true
    },
  },
  data() {
    return {
       getRepList: null
    };
  },
  methods: {
    resendReferralLink (refferralId) {
        const url = `v2/referal/resend-referral?id=${refferralId}`;
        this.isSend = true;
        let loader = this.$loading.show({
          container: this.$refs.sendReferralLink,
        });
        Api.post(url, this.form)
            .then((res) => {
              this.$notify(res.data.message);
            })
            .catch((err) => {
              console.log(err)
              this.$notify(err.response.data.message, "error");
            })
            .finally(() => {
              this.isSend = false;
              loader.hide();
            });
    }
  },
};
</script>
<style scoped>
.referral-info {
  margin: 0 0 30px 0;
  padding: 10px 15px;
  font-size: 15px;
  background-color: #ff7a00;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
}
.referral-btn {
  padding: 6px 10px;
  padding-bottom: 5px;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid grey;
  color: #000;
}
</style>
